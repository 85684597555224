import { api } from "..";

export const AdminServices = {
    registerUser:(payload:any) => 
    api.post(`/admin/user/register/v2`,payload).then((data) => data?.data),


    registerScreen:(payload:any) => 
    api.post(`/admin/screen/register/v2`,payload).then((data) => data?.data),

    getMerchantCampaigns: (payload: any) => api.get(`/admin/campaign/merchant/org/${payload.orgId}/page/${payload.page}`).then((data) => data?.data),

    getUserByEmail:(email:string) =>   api.get(`admin/user/email/${email}`).then((data) => data?.data),

    getUserById:(id:string) =>   api.get(`admin/user/id/${id}`).then((data) => data?.data),

    getScreenList:(pageNumber:number | string) =>   api.get(`admin/screen/state/ALL/city/ALL/page/${pageNumber}`).then((data) => data?.data),

    getScreenById:(id:number | string | undefined) =>   api.get(`public/screen/${id}`).then((data) => data?.data),

}