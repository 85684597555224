import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, TextField, Button } from '@mui/material';
import { Table } from '../../../components/Table/Table.component';
import { FontWeightEnum, Text, TextVariantsEnum } from '../../../components/Text';
import styles from './campaignsList.module.scss';
import { AdminServices } from '../../../services/admin/AdminServices';
import { useCampaignsSelector } from "../../../store/campaigns/useCampaignsSelector";
import { useCampaignsActions } from "../../../store/campaigns/useCampaignsActions";
import { useLoginSelector } from "../../../store/login/useLoginSelectors";
import { requestStatusEnum } from "../../../store/store.types";
import { columnConfigType } from '../../../components/Table/Table.types';
import { StatusBadge } from '../../../components/StatusBadge/StatusBadge.component';
import { dateFormatter, formatEpochToISTDateTime } from '../../../utils/dateFormatter';
import { capitalize } from '../../../utils/capitalize';

const MerchantCampaignList: React.FC = () => {
    const [startRecord, setStartRecord] = useState<number>(0);
    const [orgId, setOrgId] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const { campaignsList } = useCampaignsSelector();
    const { actionFetchMerchantCampaigns,resetCampaigns } = useCampaignsActions();

    useEffect(() => {
        if (orgId) {
            handleSearch();
        }
    }, [startRecord]);

    useEffect(() => {
        return () => {
            resetCampaigns();
        };
    }, []);

    const handleSearch = () => {
        if (!orgId.trim()) {
            setError('Please enter a valid Org Id');
            return;
        }
        actionFetchMerchantCampaigns({
            orgId: orgId.trim(),
            page: startRecord
        });
    };

    const onNameClick = (name: string) => {
        console.log('Clicked campaign:', name);
    };

    const onPaginationClick = (newStartRecord: number, recordsPerPage: number) => {
        setStartRecord(newStartRecord);
    };

    const campaignsTableColumns: columnConfigType<any>[] = [
        {
            id: 'name',
            name: 'Campaign Name',
            cellRenderer: (data) => (
                <div>
                    <Text
                        variant={TextVariantsEnum.Text_md}
                        fontWeight={FontWeightEnum.Normal}
                        color={'neutral-70'}
                        onClick={() => onNameClick(data.name)}
                    >
                        {capitalize(data.name)}
                    </Text>
                </div>
            )
        },
        {
            id: 'status',
            name: 'Status',
            cellRenderer: (data) => (
                <div>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Normal}>
                        <StatusBadge status={data.status.toLowerCase()} />
                    </Text>
                </div>
            )
        },
        {
            id: 'startTime',
            name: 'Start Time',
            cellRenderer: (data) => (
                <div>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Normal}>
                        {formatEpochToISTDateTime(Number(data.startTime))}
                    </Text>
                </div>
            )
        },
        {
            id: 'endTime',
            name: 'End Time',
            cellRenderer: (data) => (
                <div>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Normal}>
                        {formatEpochToISTDateTime(Number(data.endTime))}
                    </Text>
                </div>
            )
        },
        {
            id: 'totalScreens',
            name: 'Total Screens',
            cellRenderer: (data) => (
                <div>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Normal}>
                        {data.totalScreens || 'N/A'}
                    </Text>
                </div>
            )
        }
    ];

    return (
        <div className={styles.campaignListWrapper}>
            <div className={styles.headerWrapper}>
                <Text
                    variant={TextVariantsEnum.Display_sm}
                    fontWeight={FontWeightEnum.Bold}
                    className={styles.header}
                >
                    Merchant Campaigns
                </Text>
                <div className={styles.searchWrapper}>
                    <TextField
                        label="Org ID"
                        variant="outlined"
                        value={orgId}
                        onChange={(e) => setOrgId(e.target.value)}
                        size="small"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                        className={styles.searchButton}
                    >
                        Search
                    </Button>
                </div>
            </div>

            {error && (
                <Text
                    variant={TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    color="error"
                    className={styles.errorMessage}
                >
                    {error}
                </Text>
            )}

            {campaignsList?.status === requestStatusEnum.PROGRESS ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <div className={styles.tableWrapper}>
                    <Table
                        data={campaignsList?.data || []}
                        columns={campaignsTableColumns}
                        totalRecords={campaignsList?.data?.length || 0}
                        startRecord={startRecord}
                        onPaginationClick={onPaginationClick}
                        emptyState="No campaigns found"
                        isScrollable
                    />
                </div>
            )}
        </div>
    );
};

export default MerchantCampaignList;