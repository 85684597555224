import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  FormControl,
  InputLabel,
  CircularProgress,
  Backdrop,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import classes from "./adminPanelForm.module.scss";
import Button from "../Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../Button/button.types";
import { AdminServices } from "../../services/admin/AdminServices";
import { Dropdown } from "../Dropdown/Dropdown.component";

const STATES = [
  { label: "Karnataka", value: "KA" },
  { label: "Uttar Pradesh", value: "UP" },
  { label: "Haryana", value: "HR" },
];

const CITIES_BY_STATE = {
  KA: [{ label: "Bengaluru", value: "BLR" }],
  UP: [{ label: "Meerut", value: "MRT" }],
  HR: [{ label: "Gurgaon", value: "GUR" }],
};

const GENDER_OPTIONS = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
];

const PUBLIC_OPTIONS = [
  { label: "True", value: "TRUE" },
  { label: "False", value: "FALSE" },
];

const INCOME_RANGE_OPTIONS = [
  { label: "<50K", value: "BELOW_50K" },
  { label: "50K - 100K", value: "BETWEEN_50_100K" },
  { label: "100K - 150K", value: "BETWEEN_100_150K" },
  { label: ">500k", value: "ABOVE_500K" },
];

const TYPE_OPTIONS = [
  { label: "TV", value: "TV" },
  { label: "Channel", value: "CHANNEL" },
];

type ScreenFormValues = {
  name: string;
  status: string;
  userId: string;
  orgId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  lat: string;
  lng: string;
  tags: string;
  resolution: string;
  startTime: string;
  endTime: string;
  image: string;
  video: string;
  merchantAdQuota: string;
  isPublic: string;
  ageRangeStart: string;
  ageRangeEnd: string;
  gender: string;
  genderPreference: string;
  storeType: string;
  storeCategory: string;
  dailyFootfallMin: string;
  dailyFootfallMax: string;
  incomeRange: string;
  socialIndicator: string;
  type: string;
};

const ScreenForm: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);
  const [isGenderOpen, setIsGenderOpen] = useState(false);
  const [isGenderPrefOpen, setIsGenderPrefOpen] = useState(false);
  const [isPublicOpen, setIsPublicOpen] = useState(false);
  const [isIncomeRangeOpen, setIsIncomeRangeOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ScreenFormValues>();

  const closeAllDropdowns = () => {
    setIsStateOpen(false);
    setIsCityOpen(false);
    setIsGenderOpen(false);
    setIsGenderPrefOpen(false);
    setIsPublicOpen(false);
    setIsIncomeRangeOpen(false);
    setIsTypeOpen(false);
  };

  const handleStateChange = (value: string) => {
    setSelectedState(value);
    setValue("state", value);
    setValue("city", "");
  };

  const handleCityChange = (value: string) => {
    setValue("city", value);
  };

  const handleGenderChange = (value: string) => {
    setValue("gender", value);
  };

  const handleGenderPrefChange = (value: string) => {
    setValue("genderPreference", value);
  };

  const handlePublicChange = (value: string) => {
    setValue("isPublic", value);
  };

  const handleIncomeRangeChange = (value: string) => {
    setValue("incomeRange", value);
  };

  const handleTypeChange = (value: string) => {
    setValue("type", value);
  };

  const handleStateOpenChange = (isOpen: boolean) => {
    closeAllDropdowns();
    setIsStateOpen(isOpen);
  };

  const handleCityOpenChange = (isOpen: boolean) => {
    closeAllDropdowns();
    setIsCityOpen(isOpen);
  };

  const handleGenderOpenChange = (isOpen: boolean) => {
    closeAllDropdowns();
    setIsGenderOpen(isOpen);
  };

  const handleGenderPrefOpenChange = (isOpen: boolean) => {
    closeAllDropdowns();
    setIsGenderPrefOpen(isOpen);
  };

  const handlePublicOpenChange = (isOpen: boolean) => {
    closeAllDropdowns();
    setIsPublicOpen(isOpen);
  };

  const handleIncomeRangeOpenChange = (isOpen: boolean) => {
    closeAllDropdowns();
    setIsIncomeRangeOpen(isOpen);
  };

  const handleTypeOpenChange = (isOpen: boolean) => {
    closeAllDropdowns();
    setIsTypeOpen(isOpen);
  };

  const onSubmit = handleSubmit(async (data) => {
    const parseData = {
      name: data.name,
      status: data.status,
      userId: data?.userId,
      orgId: data?.orgId,
      address: {
        line1: data.addressLine1,
        line2: data.addressLine2,
        city: data.city,
        state: data.state,
        country: data.country,
        zip: data.zip,
        lat: data.lat,
        lng: data.lng,
      },
      tags: data.tags.split(",").map((tag) => tag.trim()),
      resolution: data.resolution,
      startTime: data.startTime,
      endTime: data.endTime,
      image: data.image,
      video: data.video,
      merchantAdQuota: data.merchantAdQuota,
      isPublic: data.isPublic === 'TRUE',
        ageRangeStart: data.ageRangeStart,
        ageRangeEnd: data.ageRangeEnd,
      gender: data.gender,
      genderPreference: data.genderPreference,
      storeType: data.storeType,
      storeCategory: data.storeCategory,
        dailyFootfallMin: data.dailyFootfallMin,
        dailyFootfallMax: data.dailyFootfallMax,
      incomeRange: data.incomeRange,
      socialIndicator: data.socialIndicator,
      type: data.type
    };

    setLoading(true);

    try {
      const response = await AdminServices.registerScreen(parseData);
      setLoading(false);
      setSnackbarOpen(true);
      setModalOpen(true);
    } catch (error) {
      setLoading(false);
      console.error('Error registering screen:', error);
    }
  });

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const availableCities = selectedState
      ? CITIES_BY_STATE[selectedState as keyof typeof CITIES_BY_STATE]
      : [];

  return (
      <div className={classes.container}>
        <h2>Register New Screen</h2>
        <form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            {/* User ID Field */}
            <Grid item xs={12}>
              <TextField
                  {...register("userId", { required: "User ID is required" })}
                  label="User Id"
                  variant="outlined"
                  fullWidth
                  error={!!errors.userId}
                  helperText={errors.userId?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  {...register("orgId", { required: "Organisation ID is required" })}
                  label="Organisation Id"
                  variant="outlined"
                  fullWidth
                  error={!!errors.orgId}
                  helperText={errors.orgId?.message}
              />
            </Grid>

            {/* Screen Name Field */}
            <Grid item xs={12}>
              <TextField
                  {...register("name", { required: "Name is required" })}
                  label="Screen Name"
                  variant="outlined"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
              />
            </Grid>

            {/* Status Field */}
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                    labelId="status-label"
                    label="Status"
                    defaultValue="ACTIVE"
                    {...register("status", { required: "Status is required" })}
                >
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Address Line 1 Field */}
            <Grid item xs={12}>
              <TextField
                  {...register("addressLine1", {
                    required: "Address Line 1 is required",
                  })}
                  label="Address Line 1"
                  variant="outlined"
                  fullWidth
                  error={!!errors.addressLine1}
                  helperText={errors.addressLine1?.message}
              />
            </Grid>

            {/* Address Line 2 Field */}
            <Grid item xs={12}>
              <TextField
                  {...register("addressLine2")}
                  label="Address Line 2"
                  variant="outlined"
                  fullWidth
              />
            </Grid>

            {/* State Dropdown */}
            <Grid item xs={6}>
              <Dropdown
                  options={STATES}
                  placeholder="Select State"
                  value={watch("state")}
                  onChange={handleStateChange}
                  isOpen={isStateOpen}
                  onOpenChange={handleStateOpenChange}
                  width="100%"
              />
            </Grid>

            {/* City Dropdown */}
            <Grid item xs={6}>
              <Dropdown
                  options={availableCities}
                  placeholder="Select City"
                  value={watch("city")}
                  onChange={handleCityChange}
                  disabled={!selectedState}
                  isOpen={isCityOpen}
                  onOpenChange={handleCityOpenChange}
                  width="100%"
              />
            </Grid>

            <Grid item xs={6}>
              <Dropdown
                  options={GENDER_OPTIONS}
                  placeholder="Select Gender"
                  value={watch("gender")}
                  onChange={handleGenderChange}
                  isOpen={isGenderOpen}
                  onOpenChange={handleGenderOpenChange}
                  width="100%"
              />
            </Grid>

            {/* Gender Preference Dropdown */}
            <Grid item xs={6}>
              <Dropdown
                  options={GENDER_OPTIONS}
                  placeholder="Select Gender Preference"
                  value={watch("genderPreference")}
                  onChange={handleGenderPrefChange}
                  isOpen={isGenderPrefOpen}
                  onOpenChange={handleGenderPrefOpenChange}
                  width="100%"
              />
            </Grid>

            {/* Public Dropdown */}
            <Grid item xs={6}>
              <Dropdown
                  options={PUBLIC_OPTIONS}
                  placeholder="Is Public?"
                  value={watch("isPublic")}
                  onChange={handlePublicChange}
                  isOpen={isPublicOpen}
                  onOpenChange={handlePublicOpenChange}
                  width="100%"
              />
            </Grid>

            {/* Income Range Dropdown */}
            <Grid item xs={6}>
              <Dropdown
                  options={INCOME_RANGE_OPTIONS}
                  placeholder="Select Income Range"
                  value={watch("incomeRange")}
                  onChange={handleIncomeRangeChange}
                  isOpen={isIncomeRangeOpen}
                  onOpenChange={handleIncomeRangeOpenChange}
                  width="100%"
              />
            </Grid>

            {/* Type Dropdown */}
            <Grid item xs={12}>
              <Dropdown
                  options={TYPE_OPTIONS}
                  placeholder="Select Type"
                  value={watch("type")}
                  onChange={handleTypeChange}
                  isOpen={isTypeOpen}
                  onOpenChange={handleTypeOpenChange}
                  width="100%"
              />
            </Grid>

            {/* Country Field */}
            <Grid item xs={6}>
              <TextField
                  {...register("country", { required: "Country is required" })}
                  label="Country"
                  variant="outlined"
                  fullWidth
                  error={!!errors.country}
                  helperText={errors.country?.message}
              />
            </Grid>

            {/* Zip Code Field */}
            <Grid item xs={6}>
              <TextField
                  {...register("zip", { required: "Zip Code is required" })}
                  label="Zip Code"
                  variant="outlined"
                  fullWidth
                  error={!!errors.zip}
                  helperText={errors.zip?.message}
              />
            </Grid>

            {/* Latitude Field */}
            <Grid item xs={6}>
              <TextField
                  {...register("lat", { required: "Latitude is required" })}
                  label="Latitude"
                  variant="outlined"
                  fullWidth
                  error={!!errors.lat}
                  helperText={errors.lat?.message}
              />
            </Grid>

            {/* Longitude Field */}
            <Grid item xs={6}>
              <TextField
                  {...register("lng", { required: "Longitude is required" })}
                  label="Longitude"
                  variant="outlined"
                  fullWidth
                  error={!!errors.lng}
                  helperText={errors.lng?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  {...register("merchantAdQuota", { required: "Merchant Ad Quota is required" })}
                  label="Merchant Ad Quota"
                  variant="outlined"
                  fullWidth
                  error={!!errors.merchantAdQuota}
                  helperText={errors.merchantAdQuota?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  {...register("ageRangeStart", { required: "Minimum Age is required" })}
                  label="Minimum Age"
                  variant="outlined"
                  fullWidth
                  error={!!errors.ageRangeStart}
                  helperText={errors.ageRangeStart?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  {...register("ageRangeEnd", { required: "Maximum Age is required" })}
                  label="Maximum Age"
                  variant="outlined"
                  fullWidth
                  error={!!errors.ageRangeEnd}
                  helperText={errors.ageRangeEnd?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  {...register("storeType", { required: "Store type is required" })}
                  label="Store Type"
                  variant="outlined"
                  fullWidth
                  error={!!errors.storeType}
                  helperText={errors.storeType?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  {...register("storeCategory", { required: "Store Category is required" })}
                  label="Store Category"
                  variant="outlined"
                  fullWidth
                  error={!!errors.storeCategory}
                  helperText={errors.storeCategory?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  {...register("dailyFootfallMin", { required: "Minimum Daily Footfall is required" })}
                  label="Minimum Daily Footfall"
                  variant="outlined"
                  fullWidth
                  error={!!errors.dailyFootfallMin}
                  helperText={errors.dailyFootfallMin?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  {...register("dailyFootfallMax", { required: "Maximum Daily Footfall is required" })}
                  label="Maximum Daily Footfall"
                  variant="outlined"
                  fullWidth
                  error={!!errors.dailyFootfallMax}
                  helperText={errors.dailyFootfallMax?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                  {...register("socialIndicator", { required: "Social Indicator is required" })}
                  label="Social Indicator"
                  variant="outlined"
                  fullWidth
                  error={!!errors.socialIndicator}
                  helperText={errors.socialIndicator?.message}
              />
            </Grid>

            {/* Tags Field */}
            <Grid item xs={12}>
              <TextField
                  {...register("tags", { required: "Tags are required" })}
                  label="Tags (comma separated)"
                  variant="outlined"
                  fullWidth
                  error={!!errors.tags}
                  helperText={errors.tags?.message}
              />
            </Grid>

            {/* Resolution Field */}
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="resolution">Resolution</InputLabel>
                <Select
                    labelId="resolution"
                    label="Resolution"
                    defaultValue="P1080"
                    {...register("resolution", { required: "Resolution is required" })}
                >
                  <MenuItem value="P1080">1080p</MenuItem>
                  <MenuItem value="P720">720p</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Start Time Field */}
            <Grid item xs={6}>
              <TextField
                  {...register("startTime", { required: "Start Time is required" })}
                  label="Start Time"
                  variant="outlined"
                  fullWidth
                  error={!!errors.startTime}
                  helperText={errors.startTime?.message}
              />
            </Grid>

            {/* End Time Field */}
            <Grid item xs={6}>
              <TextField
                  {...register("endTime", { required: "End Time is required" })}
                  label="End Time"
                  variant="outlined"
                  fullWidth
                  error={!!errors.endTime}
                  helperText={errors.endTime?.message}
              />
            </Grid>

            {/* Action Buttons */}
            <Grid item xs={12}>
              <div className={classes.buttonsContainer}>
                <Button
                    variant={ButtonVariantEnum.primary}
                    size={ButtonTextSizeEnum.lg}
                    text="Register"
                    onClick={onSubmit}
                />
                <Button
                    variant={ButtonVariantEnum.ternary}
                    size={ButtonTextSizeEnum.lg}
                    text="Cancel"
                    onClick={() => {
                      // Handle cancel action
                    }}
                />
              </div>
            </Grid>
          </Grid>
        </form>

        {/* Success Modal */}
        <Dialog open={modalOpen} onClose={handleCloseModal}>
          <DialogTitle style={{ backgroundColor: "#6938EF", color: "#fff" }}>
            <Typography variant="h5" component="div" align="center">
              Screen Registration Successful
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Box p={2} textAlign="center">
              <Typography variant="h6" style={{ color: "#6938EF" }}>
                Your screen has been registered successfully!
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
                variant={ButtonVariantEnum.primary}
                size={ButtonTextSizeEnum.sm}
                text="Ok"
                onClick={handleCloseModal}
                width={80}
            />
          </DialogActions>
        </Dialog>

        {/* Loading Backdrop */}
        <Backdrop open={loading} style={{ zIndex: 1300, color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* Success Snackbar */}
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
        >
          <Alert
              onClose={handleCloseSnackbar}
              severity="success"
              sx={{ width: "100%" }}
          >
            Screen registered successfully!
          </Alert>
        </Snackbar>
      </div>
  );
};

export default ScreenForm;