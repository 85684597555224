
export interface Video {
    id: string;
    link: string;
    uploadedBy: string;
    uploadedOn: string;
    status:string;
    thumbnail:any;
    name:string;
    length: number;
  }
  
  // export const initialVideos: Video[] = [
  //   { id: '1', link: 'video1.mp4', uploadedBy: 'User1', uploadedOn: '2024-05-20', status: 'approved' },
  //   { id: '2', link: 'video2.mp4', uploadedBy: 'User2', uploadedOn: '2024-05-19', status: 'pending' },
  //   { id: '3', link: 'video3.mp4', uploadedBy: 'User3', uploadedOn: '2024-05-18', status: 'rejected' },
  // ];
  
 export  const statusColors : {[key:string]:string} = {
    approved: 'chipApproved',
    pending: 'chipPending',
    rejected: 'chipRejected',
  };
  

  export const buttonActions = {
    approved:'approved',
    rejected:'rejected'
  }