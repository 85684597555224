import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionFetchMerchantCampaigns,
  actionFetchMediaLibrary,
  actionSetSelectedMedia,
  resetCampaigns,
  actionFetchScreensList,
  actionSetSelectedScreen
 } from './campaigns.reducer';

export const useCampaignsActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
        actionFetchMerchantCampaigns,
        actionFetchMediaLibrary,
        actionSetSelectedMedia,
        resetCampaigns,
        actionFetchScreensList,
        actionSetSelectedScreen
    },
    dispatch
  );
};