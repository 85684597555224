import { ButtonVariantEnum } from "../../components/Button/button.types";

export const tabsConstants = {
    form:"form",
    videos:"videos",
    users:"users",
    wallet:"waller",
    campaigns: "campaigns",
    screens: "screens"
}

export const headerButtonsArray = [
    { text: "Media", view: tabsConstants.videos, variant: ButtonVariantEnum.secondary },
    { text: "Registration", view: tabsConstants.form, variant: ButtonVariantEnum.secondary },
    { text: "Users", view: tabsConstants.users, variant: ButtonVariantEnum.secondary },
    { text: "Wallet", view: tabsConstants.wallet, variant: ButtonVariantEnum.secondary },
    { text: "Campaigns", view: tabsConstants.campaigns, variant: ButtonVariantEnum.secondary },
    { text: "Screens", view: tabsConstants.screens, variant: ButtonVariantEnum.secondary },
  ];

export const formTabs = {
    USER:0,
    SCREENS:0
}