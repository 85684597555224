import { call, takeLatest, all, put } from "redux-saga/effects";
import {
    actionFetchMediaLibrary,
    actionFetchMediaLibraryFailed,
    actionFetchMediaLibrarySuccess,
    actionFetchMerchantCampaigns, actionFetchMerchantCampaignsFailed, actionFetchMerchantCampaignsSuccess,
    actionFetchScreensList,
    actionFetchScreensListFailed,
    actionFetchScreensListSuccess
} from "./campaigns.reducer";
import { CampaignsService } from "../../services/campaigns/CampaignsService";
import { TActionGetCampaignsListPayload } from "./campaigns.types";
import {AdminServices} from "../../services/admin/AdminServices";

function* fetchMerchantCampaignsList({payload}: TActionGetCampaignsListPayload): any{
    try {
        const data= yield call(AdminServices.getMerchantCampaigns, {orgId: payload.orgId, page: payload.page});
        const successData = data?.response?.campaigns;
        yield put(actionFetchMerchantCampaignsSuccess({data: successData}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchMerchantCampaignsFailed())
    }
}

function* fetchMediaLibrary(): any{
    try {
        const data= yield call(CampaignsService.getMediaLibrary)                       
        yield put(actionFetchMediaLibrarySuccess({data}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchMediaLibraryFailed())
    }
}

function* fetchScreensList({payload}: TActionGetCampaignsListPayload): any{
    try {
        // const data= yield call(CampaignsService.getScreensList, {page: payload.page})
        // yield put(actionFetchScreensListSuccess({data: data.response}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchScreensListFailed())
    }
}

export function* campaignsWatcher() {
    yield all([
        takeLatest(actionFetchMerchantCampaigns.type, fetchMerchantCampaignsList),
        takeLatest(actionFetchMediaLibrary.type, fetchMediaLibrary),
        takeLatest(actionFetchScreensList.type, fetchScreensList)
    ])
}