import React from 'react';
import styles from './Dropdown.module.scss';
import { SvgIcon } from '../SvgIcon';
import { Text, TextVariantsEnum } from '../Text';

interface DropdownOption {
    label: string;
    value: string;
}

interface DropdownProps {
    options: DropdownOption[];
    placeholder: string;
    value: string | null;
    onChange: (value: string) => void;
    width?: string | number;
    height?: string | number;
    maxHeight?: string | number;
    className?: string;
    isOpen?: boolean;
    onOpenChange?: (isOpen: boolean) => void;
    disabled?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
                                                      options,
                                                      placeholder,
                                                      value,
                                                      onChange,
                                                      width = '200px',
                                                      height = '40px',
                                                      maxHeight = '200px',
                                                      className = '',
                                                      isOpen: externalIsOpen,
                                                      onOpenChange,
                                                      disabled = false
                                                  }) => {
    const [internalIsOpen, setInternalIsOpen] = React.useState(false);

    const isOpen = externalIsOpen !== undefined ? externalIsOpen : internalIsOpen;
    const setIsOpen = (newState: boolean) => {
        if (disabled) return;
        if (onOpenChange) {
            onOpenChange(newState);
        } else {
            setInternalIsOpen(newState);
        }
    };

    const toggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionClick = (optionValue: string) => {
        if (!disabled) {
            onChange(optionValue);
            setIsOpen(false);
        }
    };

    const selectedOption = options.find(option => option.value === value);

    const widthStyle = typeof width === 'number' ? `${width}px` : width;
    const heightStyle = typeof height === 'number' ? `${height}px` : height;
    const maxHeightStyle = typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight;

    const dropdownStyle = {
        width: widthStyle,
        '--dropdown-height': heightStyle,
        '--dropdown-max-height': maxHeightStyle,
    } as React.CSSProperties;

    return (
        <div
            className={`${styles.dropdown} ${className} ${disabled ? styles.disabled : ''}`}
            style={dropdownStyle}
        >
            <div className={styles.dropdown_header} onClick={toggleDropdown}>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    color={!selectedOption || disabled ? 'neutral-50' : 'neutral-70'}
                    className={styles.dropdown_text}
                >
                    {selectedOption ? selectedOption.label : placeholder}
                </Text>
                <SvgIcon
                    src={'icons/arrow.svg'}
                    rotate={isOpen ? "90" : "270"}
                    size={12}
                    className={styles.dropdown_arrow}
                />
            </div>
            {isOpen && !disabled && (
                <ul className={styles.dropdown_list}>
                    {options.map((option) => (
                        <Text
                            key={option.value}
                            variant={TextVariantsEnum.Text_sm}
                            className={styles.dropdown_list_item}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                        </Text>
                    ))}
                </ul>
            )}
        </div>
    );
};