import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, Select, MenuItem, FormControl, InputLabel, CircularProgress, Backdrop } from "@mui/material";
import classes from "./adminPanelForm.module.scss";
import Button from "../Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../Button/button.types";
import { AdminServices } from "../../services/admin/AdminServices";

type FormValues = {
  name: string;
  orgName: string;
  phoneNumber: string;
  email: string;
  referralCode: string;
  orgType: string;
  type: string;
  role: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: number;
  category: string;
  subCategory: string;
  socialIndicator: string;
};

const AdminForm: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userData, setUserData] = useState<{ userId: string; password: string } | null>(null);
  const [loading, setLoading] = useState(false);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);

  const defaultValues = {
    type: "CUSTOMER",
    role: "ADMIN",
    state: "",
    city: "",
    name: "",
    orgName: "",
    orgType: "",
    phoneNumber: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    zip: undefined,
    category: "",
    subCategory: "",
    socialIndicator: ""
  };

  const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm<FormValues>({
    defaultValues
  });

  const selectedState = watch("state");
  const selectedCity = watch("city");

  const stateOptions = [
    { label: "Karnataka", value: "KA" },
    { label: "Haryana", value: "HR" },
    { label: "Uttar Pradesh", value: "UP" }
  ];

  const cityMapping = {
    KA: [{ label: "Bengaluru", value: "BLR" }],
    HR: [{ label: "Gurgaon", value: "GUR" }],
    UP: [{ label: "SRE", value: "SRE" }]
  };

  const handleStateChange = (event: any) => {
    const newState = event.target.value;
    setValue("state", newState);
    setValue("city", "");
  };

  const handleCityChange = (event: any) => {
    setValue("city", event.target.value);
  };

  const handleStateOpen = () => {
    setIsStateOpen(true);
    setIsCityOpen(false);
  };

  const handleCityOpen = () => {
    setIsCityOpen(true);
    setIsStateOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    const parseData = {
      email: data.email,
      name: data.name,
      type: data.type,
      organization: {
        name: data.orgName,
        type: data.orgType
      },
      role: data.role,
      address: {
        line1: data.addressLine1,
        line2: data.addressLine2,
        city: data.city,
        state: data.state,
        country: data.country,
        zip: data.zip
      },
      category: data.category,
      subCategory: data.subCategory,
      socialIndicator: data.socialIndicator
    };

    setLoading(true);
    AdminServices.registerUser(parseData)
        .then(response => {
          setLoading(false);
          if (response?.response?.status === "SUCCESS") {
            setUserData({
              userId: response.response.userId,
              password: response.response.password,
            });
            setModalOpen(true);
          }
        })
        .catch(error => {
          setLoading(false);
          console.error('Error registering user:', error);
        });
  });

  const handleCloseModal = () => {
    setModalOpen(false);
    setUserData(null);
  };

  return (
      <div className={classes.container}>
        <h2>Registration Form</h2>
        <form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Name"
                          variant="outlined"
                          fullWidth
                          error={!!errors.name}
                          helperText={errors.name?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                  name="orgName"
                  control={control}
                  rules={{ required: "Organization Name is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Organization Name"
                          variant="outlined"
                          fullWidth
                          error={!!errors.orgName}
                          helperText={errors.orgName?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                  name="orgType"
                  control={control}
                  rules={{ required: "Organization Type is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Organization Type"
                          variant="outlined"
                          fullWidth
                          error={!!errors.orgType}
                          helperText={errors.orgType?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ required: "Phone Number is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          error={!!errors.phoneNumber}
                          helperText={errors.phoneNumber?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid email address"
                    }
                  }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Email"
                          variant="outlined"
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                  name="addressLine1"
                  control={control}
                  rules={{ required: "Address Line 1 is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Address Line 1"
                          variant="outlined"
                          fullWidth
                          error={!!errors.addressLine1}
                          helperText={errors.addressLine1?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                  name="addressLine2"
                  control={control}
                  rules={{ required: "Address Line 2 is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Address Line 2"
                          variant="outlined"
                          fullWidth
                          error={!!errors.addressLine2}
                          helperText={errors.addressLine2?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                  name="country"
                  control={control}
                  rules={{ required: "Country is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Country"
                          variant="outlined"
                          fullWidth
                          error={!!errors.country}
                          helperText={errors.country?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                  name="zip"
                  control={control}
                  rules={{ required: "Zip Code is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Zip Code"
                          variant="outlined"
                          fullWidth
                          type="number"
                          error={!!errors.zip}
                          helperText={errors.zip?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                  name="category"
                  control={control}
                  rules={{ required: "Category is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Category"
                          variant="outlined"
                          fullWidth
                          error={!!errors.category}
                          helperText={errors.category?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                  name="subCategory"
                  control={control}
                  rules={{ required: "SubCategory is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Subcategory"
                          variant="outlined"
                          fullWidth
                          error={!!errors.subCategory}
                          helperText={errors.subCategory?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                  name="socialIndicator"
                  control={control}
                  rules={{ required: "Social Indicator is required" }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Social Indicator"
                          variant="outlined"
                          fullWidth
                          error={!!errors.socialIndicator}
                          helperText={errors.socialIndicator?.message}
                      />
                  )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="type-label">Type</InputLabel>
                <Controller
                    name="type"
                    control={control}
                    defaultValue="CUSTOMER"
                    render={({ field }) => (
                        <Select
                            {...field}
                            labelId="type-label"
                            label="Type"
                        >
                          <MenuItem value="CUSTOMER">Customer</MenuItem>
                          <MenuItem value="MERCHANT">Merchant</MenuItem>
                          <MenuItem value="SIGNAGE">Signage</MenuItem>
                        </Select>
                    )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="role-label">Role</InputLabel>
                <Controller
                    name="role"
                    control={control}
                    defaultValue="ADMIN"
                    render={({ field }) => (
                        <Select
                            {...field}
                            labelId="role-label"
                            label="Role"
                        >
                          <MenuItem value="ADMIN">Admin</MenuItem>
                        </Select>
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="state-label">State</InputLabel>
                <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            labelId="state-label"
                            label="State"
                            onChange={(e) => {
                              field.onChange(e);
                              handleStateChange(e);
                            }}
                            onOpen={handleStateOpen}
                            onClose={() => setIsStateOpen(false)}
                            open={isStateOpen}
                        >
                          {stateOptions.map((state) => (
                              <MenuItem key={state.value} value={state.value}>
                                {state.label}
                              </MenuItem>
                          ))}
                        </Select>
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="city-label">City</InputLabel>
                <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            labelId="city-label"
                            label="City"
                            disabled={!selectedState}
                            onOpen={handleCityOpen}
                            onClose={() => setIsCityOpen(false)}
                            open={isCityOpen}
                        >
                          {selectedState && cityMapping[selectedState as keyof typeof cityMapping].map((city) => (
                              <MenuItem key={city.value} value={city.value}>
                                {city.label}
                              </MenuItem>
                          ))}
                        </Select>
                    )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.buttonsContainer}>
                <Button variant={ButtonVariantEnum.primary} size={ButtonTextSizeEnum.lg} text="Register" onClick={onSubmit} />
                <Button variant={ButtonVariantEnum.ternary} onClick={() => {}} size={ButtonTextSizeEnum.lg} text="Cancel" />
              </div>
            </Grid>
          </Grid>
        </form>

        <Dialog open={modalOpen} onClose={handleCloseModal}>
          <DialogTitle style={{ backgroundColor: "#6938EF", color: "#fff" }}>
            <Typography variant="h5" component="div" align="center">
              Registration Successful
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            {userData && (
                <Box p={2} textAlign="center">
                  <Typography variant="h6" style={{ color: "#6938EF" }}>
                    Your account has been created successfully!
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    <strong>User ID:</strong> {userData.userId}
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    <strong>Password:</strong> {userData.password}
                  </Typography>
                </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant={ButtonVariantEnum.primary} size={ButtonTextSizeEnum.sm} text="Ok" onClick={handleCloseModal} width={80} />
          </DialogActions>
        </Dialog>

        <Backdrop open={loading} style={{ zIndex: 1300, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
  );
};

export default AdminForm;